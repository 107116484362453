import { render, staticRenderFns } from "./ResultBoardRoulette.vue?vue&type=template&id=11320e91&scoped=true&"
import script from "./ResultBoardRoulette.vue?vue&type=script&lang=js&"
export * from "./ResultBoardRoulette.vue?vue&type=script&lang=js&"
import style0 from "./ResultBoardRoulette.vue?vue&type=style&index=0&id=11320e91&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11320e91",
  null
  
)

export default component.exports